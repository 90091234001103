.social-network {
  position: absolute;
  top: -70px;
  right: 0;
  width: 15%;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  z-index: 10;

  @media screen and (max-width: 800px) {
    width: 20%;
    margin-right: 25px;
    
  }

  ul {
    display: flex;
    width: 100%;
    min-width: 100px;
    justify-content: space-around;

    @media screen and (max-width: 450px) {
      min-width: 100%;
    }
    li {
      a {
        padding: 1rem;
        transition: 1.1s ease-out;
        i {
          color: $color-2;
        }
        &:hover {
          i {
            color: $color-5;
          }
        }
      }
    }

  }
}