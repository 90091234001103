.carousel-root {  
  margin-top: 20px !important;
  box-shadow: 0px 2px 10px 0px rgba(121, 119, 119, 0.78); 
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.7);

 @media screen and(max-width :672px){
   width: 100%;

}
.span-apropos{
  font-weight: bold;
}
.carousel .slide .project-circle img{
  width: 180px;
  height: 180px;
  border-radius: 50%;   
  background-size: cover;
  filter: grayscale(20%); 
  float:left;
  shape-outside: circle();
  margin: 15px;
}
.carousel{
  position: relative;
    width: 80%;
    text-align: center;  
}

.carousel.carousel-slider {
  margin: 0px auto;
  overflow: visible;
  padding-bottom:30px !important;
  width: 100% !important;
  @media screen and(max-width :1300px){
    width: 100% !important;
  }
}

.carousel .slide {
  width: 100%;
  color: $color-2;
  background: $color-1;
}

.carousel .slide-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  .partenairs_card{
    max-width:100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
   justify-items: center;
    padding: 20px;
    margin-top: 10px;
    box-shadow: 0px 2px 10px 0px rgba(121, 119, 119, 0.78);
    .partenairs_img{
      align-items: center;
      width:170px;
      height: 170px;
      margin: 0 auto;
      img{
        align-items: center;
        padding: 10px !important;
          width:170px; /* you can use % */
          height: auto;      
        object-fit: cover;
        box-shadow: 0px 2px 10px 0px rgba(121, 119, 119, 0.78);
      }
    }
    .partenairs_content{
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: baseline;
      .partenairs_title{
       
        font-size: 25px;
        font-weight: 600;
        color:$color-2;
        }
        .partenairs_sub_title{
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          color:$color-3;
          }
          p{
          
            font-size: 15px;
            padding: 10px 15px;
            margin: 5px;
          }
          .partenairs_logo_social{
            display: flex;
            width: 50%;
            margin: 15px auto;
            justify-content: center;
            background: #6f0707;         
            border-radius: 5px;
            padding: 5px;
           
            .network {
              align-self: flex-end;
              padding: 15px;
              font-size: 1.2em;
              color: hsla(0, 0%, 100%, 0.65);
              i{
                color:white;
                transition: 0.2s;  
                &:hover{
                  transform: scale(1.3);
                }                  
              }
              
            }
          }
    }
  }
}

.carousel .slide iframe {
  margin: 30px;
  width: 35%;
}
.carousel .text-container {
  padding: 30px;
  text-align: left;
}

.carousel .text-container h2 {
  margin-top: 0px;
  margin-bottom: 15px;
  color:$color-4;
  font-size: 1.5em;
  padding: 20px;
}
.carousel .equipe_content{
  display:flex;
  justify-content: space-around;
  text-align: center;
}
.carousel .equipe_content div{
width: 30%;
 margin: 0 25px;
}
.carousel .equipe_content div:nth-child(2){
  width: 70%;
   margin: 0 25px;
  }
.carousel .equipe_content img{
        height: 230px;
        padding: 10px;
        width: 230px !important;
}
.carousel .equipe_content img{
  height: 230px;
  padding: 10px;
  width: 230px !important;
}

.carousel .text-container p:last-child {
  margin-bottom: 0px;
  line-height: 25px;
  font-size: 1.2em;
}
.carousel .slider-wrapper{
  margin-top: -30px !important;
  padding-top: 40px;
}


.carousel .thumbs-wrapper {
  margin: 0 auto;
  width: 80% !important;
}
.carousel.carousel-slider button {
 
  color: $color-2 !important;
  
}
}
@media (max-width: 968px) {
  .carousel-root .carousel .text-container {
  .equipe_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    .equipe_content_img{
      width: 80%;
      margin: 0 auto;
      img{
        width: 80%;
      }
     p:last-child {
        width: 100%;
        margin: 10px 15px;
    }
    }
    div:nth-child(2){
      width: 100%;
    }
  }
  
    
}

}
