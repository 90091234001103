
/*******************card contact******************/

.contact-card-address{
  width: 60%;  
  margin: 0 auto;
  margin-top: -9npm sat0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 740px){
    width: 90%;
   }
   @media screen and (max-width: 350px){
    width: 100%;
   }

 
.card{
  position: relative;
  width: 60%;
  height: 200px;
  margin: 0 auto;
  text-align: center;
  /* padding: 15px; */
  border:none;
  margin-bottom: -20px;
  margin-top: 160px;
  @media screen and (max-width: 792px){
    margin-top: 0px;
  }
  
  .front,.back{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition:all 0.5s ease-in-out;
  }
  .front{
    background: rgb(111,7,7);
    background: linear-gradient(140deg, rgba(111,7,7,1) 0%, rgba(111,7,7,1) 38%, rgb(250, 215, 215) 72%, rgb(233, 164, 164) 87%, rgba(111,7,7,1) 100%);
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-size:160% 100%;
    z-index: 1;
    img{
      width: 28%;
    }
    h4{
      margin-top: 5px;
      color:white;
      font-size: 1.5em;
    }
    p{
      font-size: 0.8em;
      color:white;
      box-shadow: none!important;
    }
    .container-social{
      display: flex;
      justify-content: center;
      .fab{
        margin: 10px;
        color: $color-1;
        font-size: 1rem;
        &:hover{
          transform: scale(1.5);
        }
        
         &:hover.fa-linkedin {
         color:#0e76a8 ;
        
        }
        &:hover.fa-facebook{
        color:#3b5998;
        }
        &:hover.fa-instagram{
        color: #8a3ab9;
        }}
      }
  }
  .back{
    box-shadow: 1px 1px 8px  $color-4;
    background: white;
    text-align: center;
    padding: 15px;
    //background-image: url('../../assets/images/logoblanc.png');
    background-size: 200% 100%;
    hr{
      padding: 0;
      border: 0;
      height: 1px;
      width: 90%;
      background-image: linear-gradient(
        to right ,
        rgba(0,0,0,0),
        #6f0707,
        rgba(0,0,0,0));
    }
    a{
      text-decoration: none;
      color: #6f0707;
    }
    .container-info{

      transition:all 0.5s ease-in-out;
      p{
        padding: 0!important;
        margin-bottom: 0;
        font-size: 0.8em;
        box-shadow: none!important;
        color:$color-2 !important;
       
      }
      .fa{
        margin: 0 5px;
        font-size: 0.7em;
      }
     }
    
  }
  .fa{
    font-size: 1.5em;
    margin: 15px;
    transition: all 0.5s;
   color:#6f0707;
   &:hover{
    transform: scale(1.5);
  }
  }

 &:hover .container-info{
  transform: translateY(50%);
   // backdrop-filter: blur(5px); 
}
&:hover .front{
  transform:translateY(-70%) ;
  background-position: right ;
 }
}
&:hover .back{
  transform: scale(1.1);
  background-position: center ;
}
  
}
