.cursor {
  width: 62px;
  height: 62px;
  border: 1px solid $color-2;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 0.5s ease;
  transition-property: transform;
  transition: top 160ms ease-out;
  transition: left 160ms ease-out;
  z-index: 30000;

}
.cursor.hovered {
  width: 30px;
  height: 30px;
}
