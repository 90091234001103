.articles {
  width: 65% !important;
  

  .article {
    min-width: 65%;
    margin: 50px auto 50px;
    background-color: $color-1;
    position: relative;
    display: grid;
    grid-template-columns: 35% 75%;
    border-radius: 5px;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.356);

    img {
      width: 300px;
      height: 300px;
      border-radius: 5px 0 0 5px;
    }

    .react-player {
      width: 300px !important;
      height: 300px !important;
      border-radius: 5px 0 0 5px;
    }

    hr {
      width: 80%;
      height: 1px;
      color: $color-4;
      // padding: 25px;
      background-color: $color-2;
    }

    .btn {
      width: 25%;
      position: absolute;
      bottom: -10%;
      right: -10%;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      background-color: $color-2;
      color: $color-1;
      padding: 10px 25px;
      font-size: 1rem;
      text-transform: uppercase;
      border-radius: 5px;
      cursor: pointer;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.294);

      i {
        margin-left: 20px;
        font-size: 1.5rem;
      }
    }

    .article__text {
      padding: 20px 40px 0;

      h1 {
        width: 75%;
        margin-top: 15px;
        color: $color-2;
        font-weight: 400;
        padding: 15px;
      }

      .article__text__star span {
        font-size: 0.8rem;
        color: $color-3;
        margin: -5px 0 20px;
      }

      p {
        box-shadow: none !important;
        margin-top: 15px;
        font-size: 0.9rem;
        width: 80%;
      }

      .date {
        padding-bottom: 15px;
      }
    }
  }
  .actualite_attente{
    box-shadow: none !important;
    font-weight: bold;
    font-size:30px !important ;
    letter-spacing: 3px;
    padding: 5px;
    text-align: center;
    a,i{
      color:$color-2;
    }
    i:hover{
      transform: scale(1.5);
    }
  }
}

@media (max-width: 750px) {
  .articles {

    .article {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 50px auto;

      img {
        width: 100%;
      }

      .btn {
        font-size: 0.7em;

        i {
          font-size: 0.7em;
        }
      }

      .article__text {
        p {
          margin-top: 0px;
          margin-bottom: 15px;
          width: 100%;
        }

        .date {
          padding-bottom: 0px;
        }
      }

    }

  }

}

@media (max-width: 1280px) {
  .articles {
    width: 100%;

    .article {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 50px auto;

      img {
        width: 100%;
      }

      .react-player {
        width: 100% !important;
      }

      .btn {
        width: 45%;
        position: absolute;
        bottom: -6%;
        right: -20%;
      }

      .article__text {
        padding: 20px 40px 0;

        h1 {
          width: 100%;
          margin-top: 0px;

        }

        .article__text__star span {
          font-size: 0.8rem;
          color: $color-3;
          margin: -5px 0 20px;
        }

        p {
          margin-top: 0px;
          margin-bottom: 15px;
          width: 100%;
        }

        .date {
          padding-bottom: 0px;
        }
      }

    }

  }

}