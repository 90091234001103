


/***********************************navigation*******************************************/
/* Masquage de la checkbox du menu responsive */
/* nav ul li {
    border-bottom: 1px solid #f3ece5;
  } */
/********************** Menu burger ********************/
/* Effet Slide/Swipe pleine page  */
.header {
	height: 200px;
}
nav {
	background: #fafeff;
	color: #7d7b7b;
	height: auto;
	z-index: 1;
	box-shadow: 1px 1px 8px #8d8d8d;
	&:after {
		content: "";
		clear: both;
		display: table;
	}
	.logo {
		display: flex;
		align-items: center;
		position: relative;
		background: #fafeff;
	}
	strong {
		color: #6e0808;
		font-family: "Dancing Script";
		letter-spacing: 2px;
		font-size: 30px;
		padding: 0 !important;
	}
	ul {
		float: right;
		margin-right: 100px;
		list-style: none;
		position: relative;
		background: #fafeff;
		li {
			float: left;
			display: inline-block;
			background: transparent;
			padding: 15px 35px;
			a {
				color: #7d7b7b;
				line-height: 70px;
				text-decoration: none;
				font-size: 15px;
				letter-spacing: 2px;
				padding: 5px 10px;
				&:hover {
					color: #6e0808;
					border-radius: 5px;
				}
			}
			&:hover {
				>ul {
					top: 100px;
					opacity: 1;
					visibility: visible;
				}
			}
		}
		ul {
			li {
				padding: 0px;
				padding-left: 15px;
				z-index: 0;
				position: relative;
				margin: 0px;
				width: auto;
				float: none;
				display: list-item;
				border-bottom: 1px solid rgb(175, 171, 171);
				a {
					&:hover {
						background: none;
						color: #6e0808;
						z-index: 9;
						border-radius: 0px;
						border-left: 2px solid #6e0808;
						font-weight: 600;
					}
					line-height: 50px;
					font-size: 14px;
					letter-spacing: 2px;
				}
			}
			z-index: 8;
			position: absolute;
			top: 100px;
			border-top: 2px solid #6e0808;
			opacity: 0;
			visibility: hidden;
			transition: top 0.3s;
			ul {
				border-top: none;
				li {
					position: relative;
					top: -60px;
					left: 150px;
				}
			}
		}
	}
	input {
		display: none;
	}
}


.logo_img {
	box-shadow: 1px 1px 8px #8d8d8d;
	width: 120px;
	position: absolute;
	top: 30px;
	left: 40px;
	z-index: 20;
	background-color: #fafeff;
	padding: 10px;
}
.show {
	display: none;
}
.icon {
	display: none;
}
.fa-plus {
	font-size: 15px;
	margin-left: 40px;
}
input#burger {
	display: none;
}
@media all and (max-width: 450px) {
	nav {
		ul {
			ul {
				margin-top: 0px;
			}
			
		}
		>ul {
			background-color: transparent;
		}
		
		
	}
	
}
@media (max-width: 968px) {
	nav {
	
		ul {
			display: block;
			width: 100%;
			margin: 0 auto;
			list-style: none;
			position: absolute;
			left: 0;
			height: auto;
			max-height: 0%;
			overflow: hidden;
			transition: max-height 1s;
			z-index: 2;
			li {
				border-bottom: #6e0808 1px solid;
				display: block;
				width: 100%;
				background-color: #fafeff;
				height: auto;
				a {
					display: block;
					width: 100%;
					text-align: center;
					text-decoration: none;
				}
			}
			ul {
				li {
					border-bottom: #6e0808 1px solid;
					display: block;
					width: 100%;
					background-color: #fafeff;
					height: auto;
					border-bottom: 0px;
					a {
						&:hover {
							border-left: none;
						}
					}
				}
				top: 70px;
				border-top: 0px;
				float: none;
				position: static;
				display: none;
				opacity: 1;
				visibility: visible;
				ul {
					a {
						padding-left: 80px;
					}
					li {
						position: static;
					}
				}
			}
		}
		.logo {
			display: block;
		}
	}
	.show {
		+ {
			a {
				display: none;
			}
		}
		display: block;
		color: #7d7b7b;
		font-size: 18px;
		padding: 0 20px;
		line-height: 70px;
		cursor: pointer;
		text-align: center;
		width: 100%;
		&:hover {
			color: #6e0808;
			font-weight: 700;
		}
	}
	ul {
		display: none;
	}
	input[id^="btn"] {
		&:checked {
			+ {
				.sous_menu {
					display: block;
					text-align: center;
				}
				ul {
					display: block;
					text-align: center;
				}
			}
		}
	}
	.logo_img {
		position: inherit;
		width: 120px;
		display: block;
		margin: 0 auto;
		box-shadow: none;
		
	}
	#menu-burger {
		background: #6e0808;
		display: block;
		padding: 0.5em 0.8em;
		color: #f3ece5;
		text-align: center;
		font-weight: bold;
		cursor: pointer;
		position: absolute;
		z-index: 10;
		top: 1em;
		left: 1em;
		.bar1 {
			display: block;
			width: 20px;
			height: 3px;
			background-color: white;
			margin: 5px 0;
			transition: 0.4s;
		}
		.bar2 {
			display: block;
			width: 20px;
			height: 3px;
			background-color: white;
			margin: 5px 0;
			transition: 0.4s;
		}
		.bar3 {
			display: block;
			width: 20px;
			height: 3px;
			background-color: white;
			margin: 5px 0;
			transition: 0.4s;
		}
		&:hover {
			opacity: 0.75;
			transition: 0.5s;
		}
	}
	#burger {
		&:checked {
			~ {
				label {
					span.bar1 {
						-webkit-transform: rotate(-45deg) translate(-4px, 8px);
						transform: rotate(-45deg) translate(-5px, 5px) !important;
					}
					span.bar2 {
						opacity: 0 !important;
					}
					span.bar3 {
						-webkit-transform: rotate(45deg) translate(-4px, -8px);
						transform: rotate(45deg) translate(-6px, -6px) !important;
					}
				}
			}
		}
	}
	input#burger {
		&:checked {
			~ {
				nav {
					ul {
						max-height: 100%;
						transition: max-height 1s;
						li {
							width: 100%;
						}
					}
				}
			}
		}
	}
}
