/*******************footer***********************/
footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);

  
  .copy,.copy a {
    background: #6f0707;
    color: hsla(0, 0%, 100%, 0.65);
    font-size: 12px;
    padding: 15px;
  }
  .footer {
    display: flex;
    flex-direction: row;
    background: #6f0707;
    height: 150px;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-size: 1rem;

    .lineResponsive{
      display:none;
      margin: 5px auto;
      height: 3px;
      width: 80%;
      color: white;
    }

    img{
      margin: 10px;
    }
  
    a {
      color: hsla(0, 0%, 100%, 0.65);
      text-align: center;
      font-size: 1rem;

      &:hover {
        color: hsla(0, 0%, 100%, 1);
        text-decoration: none;
      }
    }

    p {
      color: hsla(0, 0%, 100%, 0.65);
      font-size: 1rem;
    }

    .reseau {
      img{
        width: 100px;
        height: 100px;
      }
      p {
        color: hsla(0, 0%, 100%, 0.65);
        font-size: 1rem;
      }

      .line,.line2 {
        margin: 5px auto;
        height: 1px;
        width: 80%;
        background-color: rgba(136, 55, 55, 0.65);
      }

      .footer-icons {
        display: flex;
        justify-content: center;
       
         
        .network {
          padding: 15px;
          font-size: 1.2em;
          color: hsla(0, 0%, 100%, 0.65);
          &:hover{
            color:white;
            i{
            transform: scale(1.5);
            }
          }
        }
        
      }

    
    }

    .footer-menu {
      display: flex;
      flex-direction: column;
      font-size: 1rem;

      a {
        padding: 5px 0px;
      }
    }
  }

  .address {
    .title-address {
      font-weight: bold;
      color: hsla(0, 0%, 100%, 0.65);
    }

  }
}




#vague {
  height: 100px;
  overflow: hidden;
  
  svg {
    height: 100%;
    width: 100%;

    path {
      stroke: none;
      fill: #6f0707;
    }

  }
}

@media (max-width: 767px) {
  footer { 
    
    .footer {  
      flex-direction: column;
      height:auto; 
      
      img{
        margin-top: 4.5em;
      } 
      p {
        color: hsla(0, 0%, 100%, 0.65);
        font-size: 1rem;
      }
      .reseau {
        margin-top: 1.5em;
        p {
          color: hsla(0, 0%, 100%, 0.65);
          font-size: 1rem;
        }
        .line {
          margin: 5px auto;
          height: 1px;
          width: 80%;
          background-color: rgba(136, 55, 55, 0.65);
        } 
        .footer-icons {
          display: flex;
          justify-content: center;
  
          .network {
            padding: 15px;
            font-size: 1.2em;
            color: white;
          }
        }   
      }
      .footer-menu {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        margin-top: 1em;
        a {
          padding: 5px 0px;
        }
      }
      .line2{
        display:none;
      }  
      .lineResponsive{
        display:block;
      }
    }
    .address {
      margin-top: 1.5em;
      .title-address {
        font-weight: bold;
        color: white;
        font-size: 1.5em;
        p{
          color: white;
          font-size: 0.7em; 
        }
      }
  
    }
  }
  #vague {
   display:none;
  }
  
}