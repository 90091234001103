/***************VIDEO***************/
.video {
  position: relative;
  box-shadow: #a9a6a6 -1px 1px 2px;
  height: auto;
  width: 100%; 
  #background-video {
      width: 100%;
      min-height: 650px;
     position: relative;
     overflow: hidden;
     color: white;   
    #video {  
    position: absolute;
       width: 100%;
       left: 0px;
       object-fit: cover;
      pointer-events: none;
      opacity: 0.7;
      z-index: 20000;
    }  
    #header-dynamic {
      position: absolute;
      z-index: 100000;
      top: 200px;
      left: 35%;
      display: none;
      width: 100%;
      margin: 0 auto;
      transition: display 2s;
      h1 {
        font-size: 5em;
        color: $color-2;
      }  
      h2 {
        font-size: 4em;
      }
    }
    #header-text {
      font-family: "Josefin Sans", sans-serif;
      font-size: 3.3em;
      z-index: 100000;
      position: absolute;
      top: 250px;
      display: block;
      text-align: center;
      width: 100%;
      margin: 0 auto;
      transition: 13s color ease-in-out;
      p {
        text-align: center;
        padding: 15px;
        color: white;
        text-shadow: -3px 3px 4px rgb(61, 19, 19) !important;
        animation: color 14s 14s;
        /**ANIMATION DU TEXT*****/
        @keyframes color {
          0% {
            color: rgb(61, 19, 19);
            text-shadow: -3px 3px 4px white;
          }
          100% {
            color: white;
            text-shadow: -3px 3px 4px rgb(61, 19, 19);
          }
        } 
      }
    }
   }
  .va-container {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
  } 
}
@media (max-width: 767px) {
  .video{
    display: none;
  }
}
@media (max-width: 959px) {
  .video{
  #background-video {
    min-height: 350px;
    #header-dynamic {
      top: 100px;
      left: 28%;    
      h1 {
        font-size: 4em;
        color: $color-2;
      }  
      h2 {
        font-size: 2em;
      }
    }
    #header-text {
     
      top: 100px;
    
      p {
      font-size: 1em;
      }
    }
  }
}
}