.entretien{

  width: 80%;
  margin: 80px auto 0px;
  box-shadow: 0px 5px 5px rgba(0,0,0,0.7);

  .entretien_header, .entretien_header_enEtat,.entretien_header_vitre,.entretien_header_panneau,.entretien_header_covid{
   width: 100%;
   height: 400px;
   background-repeat: no-repeat;
   background-size: 100% auto;
   background-position: bottom;
   box-shadow: 0px 5px 5px rgba(0,0,0,0.7);
   @media screen and (max-width: 790px) {
    height: 150px;
  }
  }
  .entretien_header{
    background-image: url('../../assets/images/entretienCourant.jpg');
  }
  .entretien_header_vitre{
    background-image: url('../../assets/images/VITRERIE.jpg');
    background-position: center ;
  }
  .entretien_header_panneau{
    background-image: url('../../assets/images/nettoyage-panneauxsolaires.jpeg');
    background-position: center ;
  }
  .entretien_header_enEtat{
    background-image: url('../../assets/images/REMISE_EN_ETAT.jpg');
    background-position: center ;
  }
  .entretien_header_covid{
    background-image: url('../../assets/images/covid.jpg');
  }
  .entretien_footer,.entretien_footer_enEtat,.entretien_footer_vitre,.entretien_footer_panneau,.entretien_footer_covid{
    margin-top: 100px;
    width: 100%;
    background-repeat: no-repeat;
   background-size: 100% auto;
   background-position: bottom ;
   box-shadow: 0px 5px 5px rgba(0,0,0,0.7);
  }
  .entretien_footer{
    background-image: url('../../assets/images/entretienCourant.jpg');
    height: 230px;
  }
  .entretien_footer_enEtat{
    background-image: url('../../assets/images/REMISE_EN_ETAT.jpg');
    height: 200px;
  }
  .entretien_footer_vitre{
    background-image: url('../../assets/images/VITRERIE.jpg');
    height: 230px;
  }
  .entretien_footer_panneau{
    background-image: url('../../assets/images/nettoyage-panneauxsolaires.jpeg');
    height: 230px;
  }
  .entretien_footer_covid{
    background-image: url('../../assets/images/covid.jpg');
    height: 230px;
  }

  .entretien_body{
    width:70%;
    margin: 0 auto; 

    h2{
      font-size: 25px;
      color:$color-2;
      padding: 25px;
      text-align: center;
      text-transform: uppercase;
    }
    
   
    .entretien_body_content1{
      display: flex;
      align-items: center;
      
        span{
          font-weight: bold;
        }
        p{
          width: 60%;
          padding: 20px;
          color: $color-2;
          line-height: 20px;
        }
        img{
          width:40%;
          height: 50%;
          background-size: cover;
          margin: 25px;
          border-radius: 5px;
        } 
        @media screen and (max-width: 872px) {
          flex-direction: column;
          p{
            width: 100%;            
          }
          img{
            width:80%;
            height: 50%;
           
          } 

        }
    
  }
  .entretien_body_content2{
    box-shadow: 0 0px 3px rgba(0,0,0,0.5);  
    margin-top: 50px;
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    h3{ 
      text-align: center;
      text-transform: uppercase;
      color:$color-2;
      font-size: 18px;
      padding: 25px;
    }
    .entretien_body_content_text{
      display: flex;
      align-items: center;

      p{
        color: white !important;
        width: 60%;
        padding: 25px ;
        color: $color-2;
        line-height: 20px;
        background: $color-2;
        height: auto;
        border-radius:5px ;

      }
      a{
        font-weight: bold;
        text-decoration: none;
        color:white;
        width: 100%;
        display:align;
        margin: 0;
        padding: 0;
      }
      img{
        width:350px;
        height: 250px;
        background-size: cover;
        margin: 25px;
        border-radius:5px ;
      }
      span{
        font-style: italic;
      } 
      @media screen and (max-width: 872px) {
        flex-direction: column;
        p{
          width: 90%;
          padding: 10px ;
          
        }
        img{
          width:90%;
          height: 250px;
          background-size: cover;
          margin: 25px;
        }
      }
    }
    
}
    a{
      text-align: center;
      padding: 10px;
      width: 22%;
      display: block;
      margin: 20px auto;
      background-color: $color-2;
      color: $color-1;
      border-radius: 25px;
      @media screen and (max-width: 872px) {
       width: 45%;
      }
    }
  }
}