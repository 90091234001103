main { 
  .a-propos-main {
    width: 90%;
    margin: 10rem auto 0;
    background-image: url(../../assets/images/FOND2.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .a-propos-content {
      margin-bottom: 100px;
      margin-top: 90px;
      width: 40%;
      margin-right: 20px;
      h1 {
        font-size: 2.4rem;
        margin-bottom: 12px;
        font-family: $font-2;
        color: $color-2;
        transform: scaleX(0);
        animation: transformTitle 1.5s ease forwards;
        transform-origin: 150%;
        @keyframes transformTitle {
          to {
            transform: scaleX(1);
          }
        }
      }
      p {
        margin-bottom: 30px;
        color: $color-4;
        font-size: 2.4rem;
        font-weight: bold;
        text-align: center;
      }
    }
    .a-propos-text-content {
      position: relative;
      text-align: left;
      margin-left: 50px;
      margin: 0 auto;
      width: 80%;
      // top:100px;
      color: $color-2;
      p {
        box-shadow: 0px 2px 10px 0px rgba(121, 119, 119, 0.78);
        line-height: 25px;
        font-size: 1rem;
        padding: 15px;
        .span-apropos {
          font-weight: bold;
        }
      }
    }
    .container-button {
      align-self: flex-start;
      margin-left: 20%;
      margin-top: 40px;
      text-align: left;
      a {
        padding: 10px 15px;
        border: #6e0808 1px solid;
        margin: 5px;
      }
    }
  }

  @media (max-width: 767px) {

    .a-propos {
      margin-top: 4rem;
      .a-propos-main {
        flex-direction: column;
        .a-propos-content {
          margin-top: 0px;
          margin-bottom: 10px;
          text-align: center;
          margin-right: 0;
          width: 90%;
          h1 {
            font-size: 1.5rem;
          }
          p {
            font-size: 1.3rem;
            width: 90%;
          }
        }
        .a-propos-text-content {
          width: 90%;
          margin: 15% 0px;
          >.flex{
            flex-direction: column;
          }
          .video_presentation {
            flex-direction: column;
            h3 {
              font-size: 0.9em;
              width: 50%;
            }
          }
        }
      }
    }
  }
}