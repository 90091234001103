
  .home-main {
    display: grid;
    align-items: center;
    justify-items: center;
    padding-left: 1rem;
    // width: auto;
    height: 95%;
    overflow: hidden;
    width: 80%;
    margin: 0 auto;
    //background-image:url(../../assets/images/FOND1.png) ;
    background-image: url(../../assets/images/FOND2.png);
    background-repeat: no-repeat;
    background-size: cover; 
    .main-content {
      user-select: none;
      margin-top: 10%;
      h1 {
        font-size: 3.7rem;
        position: relative;
        font-family: $font-2;
        color: $color-2;
        @media screen and (min-width: 200px) and (max-width: 450px) {
          font-size: 2rem;
        }
        @media screen and (min-width: 450px) and (max-width: 800px) {
          font-size: 2.6rem;
        }
        @media screen and (min-width: 800px) and (max-width: 1200px) {
          font-size: 3.2rem;
        }
        @media screen and (min-width: 1200px) and (max-width: 2000px) {
          font-size: 5rem;
        }
      
      }
      h2 {
        font-size: 4.2rem;
        font-weight: 100;
        @media screen and (min-width: 200px) and (max-width: 450px) {
          font-size: 1.2rem;
        }
        @media screen and (min-width: 450px) and (max-width: 800px) {
          font-size: 2.2rem;
        }
        @media screen and (min-width: 800px) and (max-width: 1200px) {
          font-size: 2.5rem;
        }
        @media screen and (min-width: 1200px) and (max-width: 2000px) {
          font-size: 5rem;
        }
      }
    }
  }

/******************SECTION ENTREPRISE****************/
.fond{
  background-image: url(../../assets/images/FOND2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
#avis{
  max-width: 80%;
  margin: 10% auto;
  color: #6f0707;
}
#entreprise {
  max-width: 80%;
  margin: 10% auto;
  color: #6f0707;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  // background-image: url(../../assets/images/FOND2.png);
  // background-repeat: no-repeat;
  // background-size: cover;
 
  .description p {
    font-size: 1.1rem;
    padding: 15px;
  }
  .description span {
    font-weight: bold;
  }
}

/******************SECTION PRESTATION****************/

#prestation {
  background: transparent;
  max-width: 80%;
  min-height: auto ;
  margin: 100px auto 0;
  color: #6f0707;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  .pictures {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    /************carte************/
.flip-box {
  overflow: hidden;
  background: transparent;
  width: 100%;
  max-width: 230px;
  height: 300px;
  perspective: 1000px;
  margin: 10px;
  .flip-box-inner {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 300px;
    transition: transform 2s;
    transform-style: preserve-3d;
    /*parametre partager de front et back*/
    .flip-box-back,
    .flip-box-front {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
     
    }
    /*deriere photo*/
    .flip-box-back {
     
      color: #6f0707;
      transform: rotateY(180deg);
      background-color: $color-1;
      
      div {
        margin: 0 auto;
      }
      p {
        font-size-adjust: auto;
        text-align: center;
        width: 100%;
        text-shadow: 1px 1px 5px white;
        padding: 15px;
        padding-top: 25px;
        
      }
    }
    /*******avant********/
    .flip-box-front {
      p {
        width: 70%;
      position: absolute;
      top: 40%;
      left: 15%;
      right: 20%;
      color: white;
      text-align: center;
      text-transform: uppercase;
      font-size: 18px;
      z-index: 1000px;
      background: #92929265;
      border-radius: 5px;
      box-shadow: 1px 1px 5px white;
      img{
        height:100%; 
        width:100%; 
        max-width: 300px; 
        max-height: 300px;
      }
    }
    }
  }
}
  }
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

@media (max-width: 959px) {
  #entreprise,#prestation {
    max-width: 90%;
    margin: 25% auto;
  }
  
}
// @media (max-width: 360px) {
//   #entreprise,#prestation {
//     max-width: 90%;
//     margin: 25% auto;
//   }
// }


