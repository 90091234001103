$duration:0.8s;


.presta {
  margin-bottom: 150px !important;
}

.around {
  justify-content: space-around;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100% !important;
  justify-content: center;

  .card_prestation {
    background-color: $color-1;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    width: 23%;
    height: auto;
    overflow: hidden;
    transition: box-shadow $duration;
    margin: 5px;

    &.actu {
      width: 40%;

      .card_prestation__thumb {
        background-color: $color-1;

        img {
          height: 220px;
          width: 70%;
          margin: 0 auto;

        }
      }
    }

    .card_prestation__thumb {
      height: 210px;
      overflow: hidden;
      background: black;
      border: 1px solid $color-4;
      //transition: height $duration;

      img {
        // height:240px;    
        width: 100%;
        height: 100%;
        display: block;
        opacity: 1;
        transition: opacity $duration, tranform $duration;
        transform: scaleY(1);


      }

    }

    .card_prestation__body {
      position: relative;
      padding: 20px;
      height: 125px;
      transition: height $duration;

      a {
        color: $color-2;
      }

      .card_prestation__title {
        margin: 0;
        padding: 0 0 10px 0;
        font-size: 22px;
        font-weight: bold;
        transition: all $duration;

      }

      .card_prestation__subtitle {
        font-size: 16px;
        padding: 0 0 10px 0;
        color: $color-3;
        margin-bottom: 10px;
      }

      .card_pestation__description {
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 20px;
        color: $color-4;
        font-size: 14px;
        padding: 4px;
        line-height: 27px;
        margin: 0;
        opacity: 0;
        transition: opacity $duration - 0.1s, transform $duration;
        transition-delay: 0s;
        transform: translateY(25px);
      }

      &:hover {
        height: 115px + 195px;
      }
    }

    &:hover {
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);

      .card_prestation__thumb {
        transition: height $duration;
        height: 90px;

        img {
          opacity: 0.6;
          transform: scale(1.2);
        }
      }

      .card_pestation__description {
        opacity: 1;
        transition-delay: 0.1s;
        transform: translateY(0);
      }

      .card_prestation__title {
        animation: titleBlur $duration;


        @keyframes titleBlur {
          0% {
            opacity: 0.6;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.6);
          }

          100% {
            opacity: 1;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0);

          }
        }
      }

      .card_prestation__subtitle {
        animation: subTitleBlur $duration;

        @keyframes subTitleBlur {
          0% {
            opacity: 0.6;
            text-shadow: 0px 4px 4px rgba($color-3, 0.6);
          }

          100% {
            opacity: 1;
            text-shadow: 0px 4px 4px rgba($color-3, 0);

          }
        }

      }


    }


  }
}

@media (max-width: 767px) {
  .flex {
    flex-direction: column;

    .card_prestation {
      width: 75%;
      margin: 10px auto;

      .card_prestation__thumb {
        img {
          width: 100%;
        }

      }
    }

  }
}

@media all and (min-width: 768px) and (max-width: 1110px) {
  .flex {

    .card_prestation {
      width: 45%;
      margin: 10px auto;

      .card_prestation__thumb {
        img {
          width: 100%;
        }

      }
    }

  }
}