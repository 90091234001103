
.partenairs{
  max-width:100%;
  overflow: hidden;
  display: flex;
  text-align: center;
  justify-items: center;
  
  box-shadow: 0px 2px 10px 0px rgba(121, 119, 119, 0.78);
  padding: 10px;
  background: $color-1;
  .partenairs_card{  
    background:#6f07071a;
    min-width: 32%;
    flex:1;
    margin: 25px ;
    padding: 15px;
    height: 470px;
    text-align: center;
    .partenairs_img{
      width: 70%;
      margin: 0 auto;
      img{
        height:100%;
        width: 100%;
        object-fit: cover;
        box-shadow: 0px 2px 10px 0px rgba(121, 119, 119, 0.78);
      }
      }   
    .partenairs_content{
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: baseline;
      .partenairs_title{
        font-size: 25px;
        font-weight: 600;
        color:$color-2;
        }
        .partenairs_sub_title{
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          color:$color-3;
          }
          p{
            font-size: 15px;
            padding: 20px 15px;
          }
          .partenairs_logo_social{
            display: flex;
            justify-content: center;
            background: #6f0707;
            border-radius: 5px;
            padding: 5px;
           
            .network {
              align-self: flex-end;
              padding: 15px;
              font-size: 1.2em;
              color: hsla(0, 0%, 100%, 0.65);
              i{
                color:white;
                transition: 0.2s;  
                &:hover{
                  transform: scale(1.3);
                }                  
              }
              
            }
          }
    }
   
  }

}
@media screen and (max-width: 700px) {
.partenairs{
  flex-direction: column;

}

}
